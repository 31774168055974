import {
  AvailabilityResponse,
  Order,
  OrderAttachment,
  OrderDetail,
  OrderExtension,
  OrderInvoice,
  OrderLease,
  EventFilters,
  OrderEvent,
  OrderGantt,
  Gantt,
  EditInternalNumber,
  OrderLeaseDetailItem,
  InactiveDetailItem,
  closeDetailReq,
  OperationTask,
  OperationTaskPayload,
} from "../dto/orders";
import { GoPage, ListPageResults } from "../dto/requests";
import { LeasingResponse } from "../dto/graphs";
import {
  GeneralStatistics,
  MonthComparison,
  OrdersCount,
  TopProduct,
  OccupancyPeriod,
} from "../dto/dashboard";
import { RangeFilter } from "../dto/requests";
import { daysBetween } from "../utils";
import { createNewDetailCarrier } from "../features/orders/ordersSlice";
import { inventoryApi } from "./inventory";
import { PAGE_SIZE, PAGE_SIZE_LEASE } from "./config";
import { API_HOST } from "../client/config";

const cleanOrder = (order: Order): Order => ({
  ...order,
  details: order.details.map((detail: OrderDetail) => {
    let carriers: any[] = [];
    if (detail.carriers[0].supplier_carrier_id !== 0) {
      carriers = [detail.carriers[0]];
      if (!detail.carriers[1].supplier_carrier_id) {
        carriers.push({
          ...detail.carriers[1],
          supplier_carrier_id: detail.carriers[0].supplier_carrier_id,
          supplier_carrier_car_plate:
            detail.carriers[0].supplier_carrier_car_plate,
        });
      }
    }
    return {
      ...detail,
      carriers: carriers,
    };
  }),
});

export interface Attribute {
  id: string;
  name: string;
}

export interface DateRange {
  start: string | null;
  end: string | null;
}

export interface QueryFilters {
  selectedAttrs?: Attribute[];
  selectedCategories?: number[];
  dateRange?: DateRange;
}

const buildFilterArgs = (filters: QueryFilters): string => {
  const params = new URLSearchParams();
  filters.selectedCategories?.forEach((cat) => {
    params.append("categories", cat.toString());
  });
  filters.selectedAttrs?.forEach((attr) => {
    params.append(`attributes__${attr.name}`, attr.id);
  });
  if (filters.dateRange?.start && filters.dateRange?.end) {
    params.append("start", filters.dateRange.start);
    params.append("end", filters.dateRange.end);
  }
  return params.toString();
};

export const ordersApi = inventoryApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getLeasingGraph: builder.query<LeasingResponse, string>({
      providesTags: ["Dashboard"],
      query: (range) => `charts/leasing-orders?range=${range}`,
    }),

    getOrdersCount: builder.query<OrdersCount, void>({
      providesTags: ["Dashboard"],
      query: (params) => `charts/order-detail-carriers-count${params}`,
    }),

    getEvents: builder.query<Order[], RangeFilter>({
      providesTags: ["Dashboard"],
      query: ({ start, end }) => {
        const params = new URLSearchParams();
        params.append("start_date", start);
        params.append("end_date", end);
        return `orders/calendar?${params.toString()}`;
      },
    }),

    getGeneralStatistics: builder.query<GeneralStatistics, QueryFilters>({
      providesTags: ["Dashboard"],
      query: (filters) => {
        const params = buildFilterArgs(filters);
        return `supplier-products/availability-resume?${params}`;
      },
    }),

    getTopProducts: builder.query<TopProduct[], QueryFilters>({
      providesTags: ["Dashboard"],
      query: (filters) => {
        const params = buildFilterArgs(filters);
        return `orders/top-supplier-products?${params}`;
      },
    }),

    getOccupancyRate: builder.query<OccupancyPeriod[], QueryFilters>({
      providesTags: ["Dashboard"],
      query: (filters) => {
        const params = buildFilterArgs(filters);
        return `orders/occupancy-rate?${params}`;
      },
    }),

    getMonthComparison: builder.query<MonthComparison, QueryFilters>({
      providesTags: ["Dashboard"],
      query: (filters) => {
        const params = buildFilterArgs(filters);
        return `orders/period-occupancy?${params}`;
      },
    }),

    getAllOrders: builder.query<ListPageResults<Order>, GoPage | void>({
      providesTags: ["Order"],
      query: (goPage) => {
        // TODO: volver esta lógica una funcion
        const params = new URLSearchParams();
        params.append("page_size", PAGE_SIZE_LEASE.toString());
        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.kind) params.append(goPage.kind, "true");
          if (goPage.search) params.append("search", goPage.search);
        }
        return `orders?${params.toString()}`;
      },
    }),

    getOrdersEvents: builder.query<Gantt[], EventFilters>({
      providesTags: ["Order"],
      query: (filters) => {
        const params = new URLSearchParams();
        if (filters.search) params.append("search", filters.search);
        if (filters.start_date) params.append("start_date", filters.start_date);
        if (filters.end_date) params.append("end_date", filters.end_date);

        return `orders/gantt?${params.toString()}`;
      },
    }),
    postOrdersEvents: builder.mutation<OrderEvent, { payload: OrderEvent }>({
      invalidatesTags: ["Order"],
      query: ({ payload }) => {
        return {
          method: "POST",
          url: `orders/gantt`,
          body: payload,
        };
      },
    }),
    putOrdersEvents: builder.mutation<OrderEvent, { payload: OrderEvent }>({
      invalidatesTags: ["Order"],
      query: ({ payload }) => {
        return {
          method: "PATCH",
          url: `orders/gantt/details/${payload.id}`,
          body: payload,
        };
      },
    }),
    removeOrderEvent: builder.mutation<void, number>({
      invalidatesTags: ["Order"],
      query: (id) => ({
        url: `orders/gantt/details/${id}`,
        method: "DELETE",
      }),
    }),

    getOrderDetail: builder.query<Order, string | number>({
      providesTags: ["Order"],
      query: (id) => `orders/${id}`,
      transformResponse: (data: Order) => {
        // TODO mover esto si es necesario reutilizar
        return {
          ...data,
          customer_id: data.customer.id,
          availability: data.details.map(() => true),
          details: data.details.map((detail) => {
            const carriers = detail.carriers;
            if (
              carriers.filter((carrier) => carrier.kind === "delivery").length <
              1
            )
              carriers.push({
                ...createNewDetailCarrier("delivery"),
                date: detail.start_date,
              });

            if (
              carriers.filter((carrier) => carrier.kind === "pickup").length < 1
            )
              carriers.push({
                ...createNewDetailCarrier("pickup"),
                date: detail.end_date,
              });

            return {
              ...detail,
              days: daysBetween(detail.start_date, detail.end_date),
            };
          }),
        };
      },
    }),

    getOrderDetails: builder.query<OrderLease[], string | number>({
      providesTags: ["OrderDetails"],
      query: (id) => `${API_HOST}/suppliers/v100/orders/${id}/details`,
    }),

    getInactiveOrderDetails: builder.query<
      InactiveDetailItem[],
      string | number
    >({
      providesTags: ["InactiveOrderDetails"],
      query: (id) => `${API_HOST}/suppliers/v100/orders/${id}/inactive-details`,
    }),

    createOrderDetails: builder.mutation<
      OrderLeaseDetailItem,
      OrderLeaseDetailItem
    >({
      invalidatesTags: ["OrderDetails", "InactiveOrderDetails"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v100/orders/${payload.order_id}/details`,
        method: "POST",
        body: payload,
      }),
    }),

    editOrderDetails: builder.mutation<
      OrderLeaseDetailItem,
      OrderLeaseDetailItem
    >({
      invalidatesTags: ["OrderDetails", "InactiveOrderDetails"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v100/orders/detail/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    replaceOrderDetails: builder.mutation<
      OrderLeaseDetailItem,
      OrderLeaseDetailItem
    >({
      invalidatesTags: ["OrderDetails", "InactiveOrderDetails"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v100/orders/detail/replace`,
        method: "POST",
        body: payload,
      }),
    }),

    getOrderInvoices: builder.query<OrderInvoice[], string | number>({
      providesTags: ["OrderInvoices"],
      query: (id) => `${API_HOST}/suppliers/v100/orders/${id}/invoices`,
    }),

    createInvoice: builder.mutation<void, { id: string; invoice: FormData }>({
      invalidatesTags: ["OrderInvoices"],
      query: ({ id, invoice }) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v100/orders/${id}/invoices`,
          body: invoice,
        };
      },
    }),

    updateInvoice: builder.mutation({
      invalidatesTags: ["OrderInvoices"],
      query: (payload) => {
        return {
          method: "PUT",
          url: `${API_HOST}/suppliers/v100/orders/invoices/${payload.id}`,
          body: payload.data,
        };
      },
    }),

    createExtension: builder.mutation({
      invalidatesTags: ["OrderExtensions", "OrderDetails"],
      query: (extension) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v100/orders/${extension.order_id}/extensions`,
          body: extension,
        };
      },
    }),

    deleteExtension: builder.mutation<void, number>({
      invalidatesTags: ["OrderExtensions", "OrderDetails"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v100/orders/extensions/${id}`,
        method: "DELETE",
      }),
    }),

    deleteOrderDetail: builder.mutation<void, number>({
      invalidatesTags: ["OrderExtensions", "OrderDetails"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v100/orders/detail/${id}`,
        method: "DELETE",
      }),
    }),

    updateExtension: builder.mutation({
      invalidatesTags: ["OrderExtensions", "OrderDetails"],
      query: (payload) => {
        return {
          method: "PUT",
          url: `${API_HOST}/suppliers/v100/orders/detail/${payload.id}`,
          body: payload.data,
        };
      },
    }),

    getExtensions: builder.query<OrderExtension[], string | number>({
      providesTags: ["OrderExtensions"],
      query: (id) => `${API_HOST}/suppliers/v100/orders/${id}/extensions`,
    }),

    getInvoices: builder.query<OrderInvoice[], string | number>({
      providesTags: ["OrderInvoices"],
      query: (id) => `${API_HOST}/suppliers/v100/orders/${id}/invoices`,
    }),

    deleteInvoice: builder.mutation<void, number>({
      invalidatesTags: ["OrderInvoices", "OrderDetails"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v100/orders/invoices/${id}`,
        method: "DELETE",
      }),
    }),

    getOrderAttachments: builder.query<OrderAttachment[], string | number>({
      providesTags: ["OrderAttachments"],
      query: (id) => `${API_HOST}/suppliers/v100/orders/${id}/attachments`,
    }),

    deleteAttachment: builder.mutation<void, number>({
      invalidatesTags: ["OrderAttachments", "OrderDetails"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v100/orders/attachments/${id}`,
        method: "DELETE",
      }),
    }),

    updateAttachment: builder.mutation({
      invalidatesTags: ["OrderAttachments"],
      query: (payload) => {
        return {
          method: "PUT",
          url: `${API_HOST}/suppliers/v100/orders/attachments/${payload.id}`,
          body: payload.data,
        };
      },
    }),

    createOrderAttachment: builder.mutation<
      any,
      { id: string; data: FormData }
    >({
      invalidatesTags: ["OrderAttachments"],
      query: ({ id, data }) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v100/orders/${id}/attachments`,
          body: data,
        };
      },
    }),

    deleteOrder: builder.mutation<void, number>({
      invalidatesTags: ["Order", "Products"],
      query: (id) => ({
        url: `orders/${id}`,
        method: "DELETE",
      }),
    }),

    createOrder: builder.mutation({
      invalidatesTags: ["Order", "Dashboard", "Products"],
      query: (order) => {
        return {
          method: "POST",
          url: "orders",
          body: {
            ...cleanOrder(order),
            draft: false,
          },
        };
      },
    }),

    createShortOrder: builder.mutation({
      invalidatesTags: ["Order", "Dashboard", "Products"],
      query: (order) => {
        return {
          method: "POST",
          url: "orders/short",
          body: order,
        };
      },
    }),

    updateOrder: builder.mutation({
      invalidatesTags: ["Order", "Dashboard", "Products"],
      query: (order) => {
        return {
          method: "PUT",
          url: `orders/${order.id}`,
          body: {
            ...cleanOrder(order),
            draft: false,
          },
        };
      },
    }),
    updateShortOrder: builder.mutation({
      invalidatesTags: ["Order", "Dashboard", "Products"],
      query: (orders) => {
        return {
          method: "PUT",
          url: `orders/short`,
          body: orders,
        };
      },
    }),

    reOpenOrder: builder.mutation({
      invalidatesTags: ["Order", "OrderExtensions", "OrderDetails"],
      query: (order) => {
        return {
          method: "PUT",
          url: `${API_HOST}/suppliers/v101/orders/${order}/`,
          body: {
            is_closed: false,
          },
        };
      },
    }),

    simulateOrder: builder.mutation({
      query: (order) => {
        return {
          method: "POST",
          url: "orders",
          body: {
            ...cleanOrder(order),
            draft: true,
          },
        };
      },
    }),

    getAvailabilityProduct: builder.query<AvailabilityResponse, OrderDetail>({
      query: (detail) => {
        const params = new URLSearchParams();
        params.append(
          "supplier_product_id",
          detail.supplier_product?.id?.toString() ?? ""
        );
        params.append("start_date", detail.start_date);
        params.append("days", detail.days.toString());
        if (detail.order_id)
          params.append("to_exclude_order_id", detail.order_id?.toString());
        return `supplier-products/availability?${params.toString()}`;
      },
    }),

    closeOrder: builder.mutation({
      invalidatesTags: ["Order", "Dashboard", "Products"],
      query: ({ order, end_date }) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v101/orders/${order}/close`,
          body: {
            date: end_date,
          },
        };
      },
    }),

    closeOrderDetail: builder.mutation<void, closeDetailReq>({
      invalidatesTags: [
        "Order",
        "Dashboard",
        "Products",
        "InactiveOrderDetails",
        "OrderDetails",
      ],
      query: (payload) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v100/orders/${payload.order_id}/close`,
          body: payload,
        };
      },
    }),

    editInternalNumber: builder.mutation<
      void,
      { id: string; data: EditInternalNumber }
    >({
      invalidatesTags: ["Order"],
      query: ({ id, data }) => {
        return {
          method: "PUT",
          url: `${API_HOST}/suppliers/v101/orders/${id}/`,
          body: data,
        };
      },
    }),

    getOrderTasks: builder.query<OperationTask[], number>({
      providesTags: ["OperationTasks"],
      query: (order_id) => {
        return `${API_HOST}/suppliers/v100/orders/${order_id}/tasks`;
      },
    }),

    getLeadTasks: builder.query<
      ListPageResults<OperationTask>,
      { lead_id: number; goPage: GoPage }
    >({
      providesTags: ["OperationTasks"],
      query: ({ lead_id, goPage }) => {
        const params = new URLSearchParams();
        if (goPage.filters.order_by) {
          if (goPage.page_size)
            params.append("page_size", goPage.page_size.toString());
          params.append("order_by", goPage.filters.order_by);
        }
        return `${API_HOST}/suppliers/v100/leads/${lead_id}/tasks?${params.toString()}`;
      },
    }),

    createOrderTask: builder.mutation<OperationTask, OperationTaskPayload>({
      invalidatesTags: ["OperationTasks"],
      query: (data) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v100/orders/${data.order_id}/tasks`,
          body: data.payload,
        };
      },
    }),

    createLeadTask: builder.mutation<OperationTask, OperationTaskPayload>({
      invalidatesTags: ["OperationTasks"],
      query: (data) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v100/leads/${data.lead_id}/tasks`,
          body: data.payload,
        };
      },
    }),

    updateOperationTask: builder.mutation<OperationTask, OperationTaskPayload>({
      invalidatesTags: ["OperationTasks"],
      query: (data) => {
        return {
          method: "PUT",
          url: `${API_HOST}/suppliers/v100/tasks/${data.task_id}`,
          body: data.payload,
        };
      },
    }),

    updateOperationTaskStatus: builder.mutation<
      OperationTask,
      { task_id: number; status: boolean }
    >({
      invalidatesTags: ["OperationTasks"],
      query: ({ task_id, status }) => {
        return {
          method: "PUT",
          url: `${API_HOST}/suppliers/v100/tasks/${task_id}`,
          body: {
            done: status,
          },
        };
      },
    }),

    deleteOperationTask: builder.mutation<void, number>({
      invalidatesTags: ["OperationTasks"],
      query: (task_id) => {
        return {
          method: "DELETE",
          url: `${API_HOST}/suppliers/v100/tasks/${task_id}`,
        };
      },
    }),

    getTasks: builder.query<ListPageResults<OperationTask>, GoPage | null>({
      providesTags: ["OperationTasks"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.page_size)
            params.append("page_size", goPage.page_size.toString());
          if (goPage.page_number)
            params.append("page_number", goPage.page_number.toString());
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "supplier_user_ids") {
                goPage.filters.supplier_user_ids?.forEach((id: number) =>
                  params.append("supplier_user_ids", `${id}`)
                );
              } else {
                params.append(key, goPage.filters[key]);
              }
            });
          }
        }
        return `tasks?${params.toString()}`;
      },
    }),
  }),
});

export const {
  useGetLeasingGraphQuery,
  useGetOrdersCountQuery,
  useGetEventsQuery,
  useGetOccupancyRateQuery,
  useGetGeneralStatisticsQuery,
  useGetTopProductsQuery,
  useGetMonthComparisonQuery,
  useGetAllOrdersQuery,
  useCreateOrderMutation,
  useSimulateOrderMutation,
  useGetAvailabilityProductQuery,
  useGetOrderDetailQuery,
  useDeleteOrderMutation,
  useUpdateOrderMutation,
  useCloseOrderMutation,
  useCloseOrderDetailMutation,
  useCreateShortOrderMutation,
  useUpdateShortOrderMutation,
  useGetOrderDetailsQuery,
  useGetInactiveOrderDetailsQuery,
  useCreateOrderDetailsMutation,
  useEditOrderDetailsMutation,
  useReplaceOrderDetailsMutation,
  useCreateExtensionMutation,
  useGetOrderInvoicesQuery,
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useGetExtensionsQuery,
  useCreateOrderAttachmentMutation,
  useGetOrderAttachmentsQuery,
  useGetOrdersEventsQuery,
  usePostOrdersEventsMutation,
  useRemoveOrderEventMutation,
  usePutOrdersEventsMutation,
  useDeleteExtensionMutation,
  useDeleteAttachmentMutation,
  useDeleteInvoiceMutation,
  useUpdateAttachmentMutation,
  useUpdateInvoiceMutation,
  useUpdateExtensionMutation,
  useEditInternalNumberMutation,
  useReOpenOrderMutation,
  useDeleteOrderDetailMutation,
  useCreateOrderTaskMutation,
  useGetOrderTasksQuery,
  useUpdateOperationTaskMutation,
  useUpdateOperationTaskStatusMutation,
  useDeleteOperationTaskMutation,
  useCreateLeadTaskMutation,
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useGetLeadTasksQuery,
} = ordersApi;
